<template>
	<div class="enterprise">
		<div class="breadcrumbTop">
			<a-breadcrumb>
				<a-breadcrumb-item @click='$router.back()'>自定义设置</a-breadcrumb-item>
				<a-breadcrumb-item><a href="">证书/证件类型设置</a></a-breadcrumb-item>
			</a-breadcrumb>

			<div style="text-align: right;">
				
				<a-button @click='openAdd' class='pointerCancel'>添加证件类型
				</a-button>
			</div>
			<div class="FieldSettingsBox">
				<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
					<span>
						&nbsp; &nbsp;
					</span>
					<span>证书/证书类型</span>
					<span>证书数</span>
					<span>是否发送到期提醒</span>
					<span>提前多少天发送提醒</span>

					<span>是否发送复审提醒</span>
					<span>提前多少天发送提醒</span>
					<span style="color: #666;">操作</span>
				</div>

				<!-- <draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
					@start="onStart" @end="onEnd">
					<transition-group> -->
						<div  :draggable="true"
                                    @dragstart="dragstart($event, -1, index)"
                                    @dragover="dragover"
                                    @dragenter="dragenter($event, -1, index)"
                                    @dragend="dragend($event, -1, index)"
                                    @drop="drop" v-show="element.status==1" class="FieldSettingsBoxTitle" v-for="(element,index) in all_list" 
							>
							<span>
								<a-icon type="unordered-list" />
							</span>
							<span>
								{{element.name}}
							</span>
							<span>
								{{element.num}}
							</span>
							<span>
								<CheckOutlined v-if='element.is_send==1' />
							</span>
							<span>
								{{element.send_time}}
							</span>
							<span>
								<CheckOutlined v-if='element.is_send_reexamine==1' />
							</span>
							<span>
								{{element.reexamine_time}}
							</span>
							<span >
								<span>
									<span class="cq_color" v-if="element.can_edit==1" @click='editInfo(element)'>编辑</span>
									&nbsp;
									<span class="cq_color" v-if="element.num>0" @click='mergeInfo(element)'>合并</span>
									<span   class="cq_color" @click='mergeInfo(element)' v-else-if="element.num==0 && element.can_edit==1">合并</span>
									&nbsp;
									<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delOk(element.id)"
										@cancel="cancel">
									&nbsp;	<a href="#" v-if="element.can_delete==1" class="cq_error">删除</a>
									</a-popconfirm>
								</span>
								
							</span>
						</div>
					<!-- </transition-group>
				</draggable> -->

			</div>
		</div>
		<a-modal v-model:visible="mergeVisible" title="提示" @ok="mergeOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="当前类型">
					<a-input disabled v-model:value="formState2.name"  />
				</a-form-item>
				<a-form-item label="合并入">
					<a-select v-model:value="formState2.merge_id" placeholder="请选择合同">
						<a-select-option v-for='item in all_list' :value="item.id">
							{{item.name}}
						</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="addVisible" :title="editType=='edit'?'编辑证件类型':'新增证件类型'" @ok="addOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="证件类型">
					<a-input v-model:value="formState.name" />
				</a-form-item>
				<a-form-item label="发送到期提醒给员工">
					<a-switch v-model:checked="formState.is_send" />
				</a-form-item>
				<a-form-item label="提前多少天发提醒" v-if='formState.is_send'>
					<a-input-number id="inputNumber" v-model:value="formState.send_time" :min="1" /> <span>天</span>
				</a-form-item>
				<a-form-item label="是否启用复审">
					<a-radio-group v-model:value="formState.is_reexamine">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="-1">否</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="是否提醒员工复审" v-if="formState.is_reexamine==1">
					<a-switch v-model:checked="formState.is_send_reexamine" />
				</a-form-item>
				<a-form-item label="提前多少天发提醒" v-if="formState.is_send_reexamine==1">
					<a-input-number id="inputNumber" v-model:value="formState.reexamine_time" :min="1" /> <span>天</span>
				</a-form-item>
				<a-form-item label="默认证书/证件名称">
					<a-input v-model:value="formState.certficate_name" />
				</a-form-item>
				<a-form-item label="默认发证机构">
					<a-input v-model:value="formState.issuing_authority" />
				</a-form-item>
				<a-form-item label="默认有效期">
					<a-input-number id="inputNumber" v-model:value="formState.valid_time" :min="1" /> <span>年</span>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		editCustomSetting,
		editCustomSettingCate,
		getCustomSettingInfo,
		getViewBookSelect,
		addCustomSetting,
		delCustomSetting,
		getCustomSettingDetail,
		changeCustomSettingStatic,
		changeCustomSettingStatus,
		sortCustomSetting,
		mergeCustomSetting,
		addCustomSettingCate,
	} from '@/api/enterprise.js'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	
	export default {
		components: {
		
		},
		data() {
			return {stop_index: 0,
				stop_approval_index: 0,
				addVisible: false,
				formState: {
					send_time: 1,
					reexamine_time: 1,
					valid_time: 1,
				},
				all_list: [],
				visit2: true,
				editType: 'add',
				company_id: '',
				company_name: '',
				merge_company: '',
				addressLitst: [],
				delVisible: false,
				editVisible: false,
				mergeVisible: false,
				delCompany: false,

				labelColEdit: {
					span: 8
				},
				wrapperColEdit: {
					span: 16
				},
				wrapperCol: {
					span: 12
				},
				formState2: {
					merge_id: ''
				},
				select_list: [],
				type: '',
			}

		},
		created() {
			this.type = this.$route.query.type
			this.getIndustry()
		},
		methods: {
			dragstart(e, ind) {
			    // e.preventDefault()
			},
			drop(e) {
			    e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
			    e.preventDefault()
			    if (ind != -1) {
			        this.stop_index = ind;
			    }
			    this.stop_approval_index = index
			},
			dragover(e) {
			    e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
			   let list = this.all_list;
			   let move_data = list[index];
			   list.splice(index, 1)
			   list.splice(this.stop_approval_index, 0, move_data)
			   this.all_list = list;
			   this.onEnd()
			
			},
			cancel(){},
			openAdd(){
				this.addVisible=true
				this.formState= {
					send_time: 1,
					reexamine_time: 1,
					valid_time: 1,
				}
				
			},
			mergeInfo(item) {
				this.formState2.name = item.name
				this.formState2.id = item.id
				this.mergeVisible = true
			},
			mergeOk() {
				mergeCustomSetting({
					data: {
						id: this.formState2.id ,
						merge_id: this.formState2.merge_id 
					},info:true
				}).then(res => {
					this.mergeVisible = false
					this.getIndustry()
					
				})
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
				let sort = []
				this.all_list.forEach(item => {
					sort.push(item.id)
				})
				sortCustomSetting({
					data: {
						sort: sort.join(',')
					},info:true
				}).then(res => {
					this.getIndustry()
				})
			},
			editInfo(item) {
				this.formState = JSON.parse(JSON.stringify(item))
				this.formState.is_send = this.formState.is_send == 1 ? 1 : -1
				this.formState.is_reexamine = this.formState.is_reexamine == 1 ? '1' : '-1'

				this.editType = 'edit'
				console.log('this.formState', this.formState);
				this.addVisible = true
			},
			addOk() {
				let opt = {
					...this.formState,
					type: this.type
				}
				opt.is_send = this.formState.is_send ? 1 : -1
				opt.is_reexamine = this.formState.is_reexamine

				if (this.editType == 'add') {
					addCustomSetting({
						data: opt,info:true
					}).then(res => {
						this.editType = 'add'
						
						this.addVisible = false
						this.formState2 = {}
						this.getIndustry()
					})
				} else {
					opt.id = this.formState.id,
						editCustomSetting({
							data: {

								...opt
							},
							info:true
							
						}).then(res => {
							this.editType = 'add'
						
							this.addVisible = false
							this.formState2 = {}
							this.getIndustry()
						})
				}

			},
			delOk(id) {
				delCustomSetting({
					data: {
						id: id
					},info:true
				}).then(res => {
					this.getIndustry()
				})
			},
			get_CustomSettingCateSelect() {
				getViewBookSelect().then(res => {
					this.select_list = res.data.list
				})
			},
			getIndustry() {
				getCustomSettingDetail({
					data: {
						type: this.type
					},info:true
				}).then(res => {

					this.all_list = res.data.list.content
				})
				this.get_CustomSettingCateSelect()
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2),
		span:nth-child(3),
		span:nth-child(4),
		span:nth-child(5),
		span:nth-child(6),
		span:nth-child(7) {
			flex: 2;
			margin-left: 8px;
			text-align: center;
		}

		span:nth-child(8) {
			width: 120px;
			margin-left: 8px;
			text-align: left;
		}

		span:nth-child(2) {

			text-align: left;
		}

		span:nth-child(3) {
			flex: .7;
			text-align: left;
		}


	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}
</style>
